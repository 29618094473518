import Auth from '@aws-amplify/auth'

export const checkIsAuth = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const userId = user.attributes ? user.attributes.sub : user.id
    const userEmail = user.attributes ? user.attributes.email : user.email
    const isAdmin = user.attributes ? user.attributes['custom:admin'] : null
    return { userId, userEmail, isAdmin }
  } catch (err) {
    throw new Error('not auth')
  }
}

export const fetchLogIn = async (ctx, event) => {
  const { login, password, history } = event.data

  try {
    await Auth.signIn(login, password)
    const user = await Auth.currentAuthenticatedUser()
    const userId = user.attributes ? user.attributes.sub : user.id
    const userEmail = user.attributes ? user.attributes.email : user.email
    const isAdmin = user.attributes ? user.attributes['custom:admin'] : null
    history.push('/dashboard')
    return { userId, userEmail, isAdmin }
  } catch (err) {
    throw new Error(err.message)
  }
}

export const fetchLogOut = async (ctx, event) => {
  const { history } = event.data
  try {
    await Auth.signOut()
    history.push('/auth')
  } catch (err) {
    throw new Error(err)
  }
}

export const fetchSignUp = async (ctx, event) => {
  const { email, firstPassword } = event.data
  try {
    const newUser = await Auth.signUp({
      username: email,
      password: firstPassword,
    })
  } catch (err) {
    throw new Error(err.message)
  }
}

export const handleConfirmationSubmit = async (ctx, event) => {
  const { newEmail, newPassword, confirmationCode } = event.data
  try {
    await Auth.confirmSignUp(newEmail, confirmationCode)
    const user = await Auth.signIn(newEmail, newPassword)
    const userId = user.attributes.sub
    const userEmail = user.attributes.email
    return { userId, userEmail }
  } catch (err) {
    throw new Error(JSON.parse(err))
  }
}
