import API from '@aws-amplify/api'
import { saveAs } from 'file-saver'
import { stage } from '../../config/config'

// dead code, need to remove
export const fetchReports = async (ctx, event) => {
  const { id } = ctx.scanInfo
  try {
    const response = await API.get(
      stage,
      `/calculator/calculation-results/${id}`,
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export const loadFullReport = async (reportId, page, pageSize, q) => {
  console.log('FULL_REPORT_FETCH')
  const DEFAULT_PAGE_SIZE = 20

  const response = await API.get(stage, `/reports/${reportId}/`, {
    queryStringParameters: {
      per_page: pageSize || DEFAULT_PAGE_SIZE,
      page: page || 1,
      q,
    },
  })
  return response
}

export const saveReportToLocalStorage = async (data, context) => {
  const saveToLocalStorage = async reports => {
    await localStorage.setItem('reports', JSON.stringify({ reports }))
  }

  const modifyExistingReportsList = (previousReports, newReport) => {
    const parsedReports = JSON.parse(previousReports).reports
    parsedReports.push(newReport)
    return parsedReports
  }
  const { type, scanId } = data

  const nameOfReport = data.fileName ? data.fileName : context.searchId
  const reportDate = Date.now()
  const currentReport = { type, scanId, reportDate, nameOfReport }

  const reportsFromStorage = await localStorage.getItem('reports')

  if (reportsFromStorage) {
    const newListOfReports = modifyExistingReportsList(
      reportsFromStorage,
      currentReport,
    )
    await saveToLocalStorage(newListOfReports)
  } else {
    saveToLocalStorage([currentReport])
  }
  return null
}

export const saveReport = async (context, event) => {
  const reportDate = Date.now()
  const { scanId, scanName, scanType, marketplace } = event
  const payload = {
    scan_id: scanId,
    scan_type: scanType,
    scan_name: scanName,
    scan_date: reportDate,
    marketplace,
  }
  await API.post(stage, '/reports', {
    body: payload,
  })
  const scanInfo = {
    type: scanType,
    // info: last.info,
    // last: last.data.rows,
    // progress: last.progress,
    // status: last.status,
    scanId,
    // fileName,
  }

  await API.post(stage, `/activescans`, {
    body: scanInfo,
  })
}

export const loadReports = async (context, event) => {
  const DEFAULT_PAGE_SIZE = 20

  const { params } = event
  try {
    const reports = await API.get(stage, '/reports', {
      queryStringParameters: {
        page: params ? params.current : 1,
        per_page: params ? params.pageSize : DEFAULT_PAGE_SIZE,
      },
    })
    return reports
  } catch (err) {
    console.error(err)
  }
}

export const deleteReports = async (context, event) => {
  await API.del(stage, '/reports', {
    body: { reports: event.reportsForDeletion },
  })
}

export const saveFullReport = async data => {
  const filename = data.result_scan_filename || data.scan_id
  console.log(data)
  const file = await API.get(stage, `/reports/${data.scan_id}/download`, {
    responseType: 'blob',
  })
  saveAs(file, filename)
}

export const saveOriginalFile = async data => {
  const { scan_id, scan_name } = data
  try {
    const file = await API.get(stage, `/files/${scan_id}`, {
      responseType: 'blob',
    })
    saveAs(file, scan_name)
  } catch (e) {
    throw new Error(e)
  }
}

export const addProductToZoho = async productId => {
  return API.post(stage, `/zoho/products/${productId}/insert`)
}
