import API from '@aws-amplify/api'
import { stage } from '../../config/config'

export const getScanFilters = async id => {
  try {
    const response = await API.get(stage, `/filters-presets/${id}/filters`)
    return response
  } catch (e) {
    console.error(e)
  }
}

export const saveScanFilters = async (ctx, event) => {
  try {
    const response = await API.put(
      stage,
      `/filters-presets/${ctx.current.id}/filters`,
      { body: event.data },
    )
    return response
  } catch (e) {
    return Promise.reject(e)
  }
}

export const addPreset = async preset => {
  try {
    const response = await API.post(stage, '/filters-presets', {
      body: preset,
    })
    return response
  } catch (e) {
    return Promise.reject(e)
  }
}

export const getPresets = async () => {
  try {
    const response = await API.get(stage, '/filters-presets')
    return response
  } catch (e) {
    return Promise.reject(e)
  }
}

export const updatePreset = async preset => {
  try {
    const response = await API.put(stage, `/filters-presets/${preset.id}`, {
      body: preset,
    })
    return response
  } catch (e) {
    return Promise.reject(e)
  }
}

export const deletePreset = async presetId => {
  try {
    const response = await API.del(stage, `/filters-presets/${presetId}`)
    return { ...response, deletedId: presetId }
  } catch (e) {
    return Promise.reject(e)
  }
}
