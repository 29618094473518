import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'

import { stage, config } from '../../config/config'

const getToken = async () => {
  const token = await Auth.currentSession()
  return token.idToken.jwtToken
}

export const initSingleScan = async (ctx, event) => {
  const init = { headers: { userId: 'userId' } }

  const { userInput } = event.data

  const user = await Auth.currentUserInfo()
  const { attributes } = user

  const params = {
    ...userInput,
    amazon_calculator: 'true',
    seller_id: attributes['custom:sellerId'],
    auth_token: attributes['custom:sellerToken'],
  }
  try {
    const response = await API.post(stage, '/scanner/search', {
      body: params,
      init,
    })
    return {
      scanId: response.id,
      brand: userInput.brand || '',
      title: userInput.title || '',
      barcode: userInput.id || null,
      partNumber: userInput.model || '',
      marketplace: userInput.marketplace,
    }
  } catch (e) {
    console.log(e)
  }
}

export const checkSingleScan = async (ctx, event) =>
  new Promise(async (resolve, reject) => {
    const { scanId } = ctx

    const last = await API.get(
      stage,
      `/reports/${scanId}`,
    )

    const response = {
      type: 'single',
      info: last.info,
      last: last.data.rows,
      progress: last.progress,
      status: last.status,
      scanId,
    }

    const scanInfo = {
      type: 'single',
      info: last.info,
      last: last.data.rows,
      progress: last.progress,
      status: last.status,
      scanId,
    }
    await API.put(stage, `/activescans/${scanId}`, {
      body: scanInfo,
    }).catch(() => reject(response))
    return resolve(response)
  })

export const getSingleScanResult = async (ctx, event) => {
  const { id } = ctx.scanInfo
  const lastResult = await API.get(
    stage,
    `/reports/${id}/last`,
  )
  return lastResult
}

export const uploadFile = async (ctx, event) => {
  const formData = new FormData()
  const uploadedFileInfo = event[0]
  formData.append('file', uploadedFileInfo)

  const response = await fetch(`${config[stage].apiGateway.URL}/files`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: await getToken(),
    },
  })
  const request = await response.json()

  const headersOfUploadedFile = await API.get(
    stage,
    `/files/${request.id}/headers`,
  )

  const uploadedFileName = uploadedFileInfo.name
  const result = {
    headers: headersOfUploadedFile,
    scanId: request.id,
    uploadedFileName,
  }
  return result
}

export const checkBulkScan = async (ctx, event) => {
  const scanId = event.data.id

  try {
    const result = await API.get(stage, `/scanner/process/${scanId}`)
    return result
  } catch (e) {
    throw new Error(e)
  }
}

export const bulkScanLoading = async ctx =>
  new Promise(async (resolve, reject) => {
    const { scanId, fileName } = ctx

    const last = await API.get(
      stage,
      `/reports/${scanId}/last`,
    )
    const scanInfo = {
      type: 'bulk',
      info: last.info,
      last: last.data.rows,
      progress: last.progress,
      status: last.status,
      scanId,
      fileName,
    }

    const response = {
      type: 'bulk',
      info: last.info,
      last: last.data.rows,
      progress: last.progress,
      status: last.status,
      scanId,
      fileName,
    }

    await API.put(stage, `/activescans/${scanId}`, {
      body: scanInfo,
    }).catch(e => reject(response))

    return resolve(response)
  })

export const bulkMapping = async (ctx, event) => {
  const userMappings = event.data.mappings
  const user = await Auth.currentUserInfo()
  const { attributes } = user

  const mappingParams = {
    ...userMappings,
    quantity_multiplier: '',
    field_1: '',
    field_2: '',
    field_3: '',
    field_4: '',
    field_5: '',
    amazon_calculator: 'true',
    scan_option: 'AUTO',
    seller_id: attributes['custom:sellerId'],
    auth_token: attributes['custom:sellerToken'],
  }

  await API.put(stage, `/files/${ctx.scanId}/mapping`, {
    body: mappingParams,
  })

  const startProcess = await API.get(stage, `/scanner/process/${ctx.scanId}`)
  // return startProcess
  return userMappings
}

export const stopScan = async (context, event) => {
  const scanId = event.data
  await API.get(stage, `/scanner/stop/${scanId}`)
  await API.del(stage, `/activescans/${scanId}`)
}

export const checkExistScan = () =>
  new Promise(async (resolve, reject) => {
    const existScanArray = await API.get(stage, '/activescans')
    const existScan = existScanArray[0]
    // const existScan = JSON.parse(localStorage.getItem('activeScan'))
    if (!existScan) {
      return reject('no item')
    }
    const response = {
      typeOfMachine: existScan.type,
      info: existScan.info,
      last: existScan.last,
      progress: existScan.progress,
      scanId: existScan.scanId,
      report: existScan.report,
      type: existScan.type,
      fileName: existScan.fileName,
    }
    return resolve(response)
  })
