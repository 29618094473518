import {
  initSingleScan,
  checkSingleScan,
  getSingleScanResult,
  uploadFile,
  checkBulkScan,
  bulkScanLoading,
  bulkMapping,
  stopScan,
  checkExistScan,
} from './scanActions'

import {
  fetchReports,
  saveReportToLocalStorage,
  loadFullReport,
  saveReport,
  loadReports,
  deleteReports,
  saveFullReport,
  saveOriginalFile,
  addProductToZoho,
} from './reportActions'

import {
  checkIsAuth,
  fetchLogIn,
  fetchLogOut,
  fetchSignUp,
  handleConfirmationSubmit,
} from './authActions'

import {
  loadTemplates,
  addTemplate,
  deleteTemplate,
  changeTemplate,
} from './shippingActions'

import { saveTokens, checkUserTokens } from './settingsActions'

import { getUserSettings, saveUserSettings } from './userProfileActions'
import {
  getScanFilters,
  saveScanFilters,
  getPresets,
  addPreset,
  deletePreset,
  updatePreset,
} from './scanFiltersActions'

export {
  initSingleScan,
  checkSingleScan,
  getSingleScanResult,
  uploadFile,
  checkBulkScan,
  bulkScanLoading,
  bulkMapping,
  fetchReports,
  checkIsAuth,
  fetchLogIn,
  fetchLogOut,
  fetchSignUp,
  handleConfirmationSubmit,
  saveReportToLocalStorage,
  loadFullReport,
  saveReport,
  loadReports,
  stopScan,
  loadTemplates,
  addTemplate,
  deleteTemplate,
  changeTemplate,
  checkExistScan,
  saveTokens,
  checkUserTokens,
  deleteReports,
  getUserSettings,
  saveUserSettings,
  saveFullReport,
  saveOriginalFile,
  getScanFilters,
  saveScanFilters,
  getPresets,
  addPreset,
  deletePreset,
  updatePreset,
  addProductToZoho,
}
